import React from 'react';
import OrderConfirmation from './components/OrderConfirmation';
import './App.css';

const App = function () {

  return (
    <>
      {
        <OrderConfirmation />
      }
    </>
  );
}
export default React.memo(App);