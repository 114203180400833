import React from 'react';
import Image from 'react-bootstrap/Image'

const OrderConfirmation = function () {
    return (
        <div className='d-flex justify-content-center align-items-center vh-100 flex-column text-center' >
            <div className="p-4 p-sm-5 message-container">
                <div className="pb-4">
                    <Image xs="12" sm="2" fluid={true} alt="forPEACE logo" src="https://images.squarespace-cdn.com/content/560ccb28e4b0a926fe0c846c/23758788-7f36-420c-8cd8-c6e7f91cc3e7/forPEACE+logo.png?content-type=image%2Fpng" />
                </div>
                <p>Check back for upcoming events.</p>
            </div>
        </div>
    )
}
export default React.memo(OrderConfirmation);