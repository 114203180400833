import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import './index.css';
import App from './App';

const firebaseConfig = {
  projectId: "forpeace-benefit-event",
  apiKey: "AIzaSyDfvx_uneLuTALdfr7McodAIZ8o666jQwI",
  appId: "1:1049302251492:web:39b84d28334565e94f79f1",
  databaseURL: "https://forpeace-benefit-event-default-rtdb.firebaseio.com",
  measurementId: "G-1XY856Z9LJ",
};

const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'production') {
  getAnalytics(app);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();